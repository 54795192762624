<template>
  <div class="entrance">
    <div class="title" @click="toggleFullscreen">
      <div class="projectName">{{ projectName }}</div>
      <div class="dataTimeStr">{{ dataTimeStr }}</div>
    </div>
    <!-- 员工个人信息 -->
    <div class="wrapLeft">
      <div class="wrapTitle">通行人员信息</div>
      <img class="icon" :src="currentPerson.idPhoto" />
      <div class="personInfo">
        <span class="personName">{{currentPerson.consumerName}}</span>
        <span class="personGz">{{currentPerson.title}}</span>
      </div>
      <div class="personNum">员工工号：{{currentPerson.consumerNo}}</div>
      <div class="personMore">
        <div class="row">
          <span class="personSpan tar">性别：{{onGetSex(currentPerson.sex)}}</span>
          <span class="personSpan tal">民族：{{currentPerson.nationality}}</span>
        </div>
        <div class="row">
          <span class="personSpan tar">人脸机：{{onGetSendface(currentPerson.sendface)}}</span>
          <span class="personSpan tal">员工状态：{{onGetWorkingState(currentPerson.workingState)}}</span>
        </div>
        <div class="row">
          <span>参建单位：{{currentPerson.corporationName}}</span>
        </div>
        <div class="row">
          <span>部门/班组：{{currentPerson.groupName}}</span>
        </div>
      </div>
    </div>
    <!-- 实时数据 -->
    <div class="wrapMidTop">
      <div class="wrapTitle">实时数据</div>
      <div class="wrapMidTopContent">
        <div class="wrapMidTopContentItem">
          <span>{{ localeNumber }}人</span>
          <div class="wrapMidTopContentItemName">现场人数</div>
        </div>
        <div class="wrapMidTopContentItem">
          <span>{{ arenaNumber }}人</span>
          <div class="wrapMidTopContentItemName">进场人数</div>
        </div>
        <div class="wrapMidTopContentItem">
          <span>{{ outNumber }}人</span>
          <div class="wrapMidTopContentItemName">退场人数</div>
        </div>
      </div>
    </div>
    <!-- 实时出入情况 -->
    <div class="wrapMidBottom">
      <div class="wrapTitle">实时出入记录</div>
      <div class="entranceWrap">
        <div class="entranceWrapItem">
          <span class="icon"></span>
          <span class="name">姓名</span>
          <span class="work">工种</span>
          <span class="inout">出入</span>
          <span class="time">时间</span>
        </div>
        <div class="entranceWrapItem" v-for="item, index in message" :key="index">
          <span class="icon">
            <img :src="item.idPhoto">
          </span>
          <span class="name">{{item.consumerName}}</span>
          <span class="work">{{item.title}}</span>
          <span class="inout">{{onGetInOut(item.inOut)}}</span>
          <span class="time">{{item.readDate}}</span>
        </div>
      </div>
    </div>
    <!-- 部门/班组出勤情况 -->
    <div class="wrapRight">
      <div class="wrapTitle">部门/班组出勤情况</div>
      <div class="groupWrap">
        <div class="groupWrapItem">
          <span class="groupName">部门/班组</span>
          <span class="attendance">出勤人数</span>
          <span class="headcount">全部人数</span>
          <span class="percents">出勤率</span>
        </div>
        <div class="groupWrapItem" v-for="item, index in groupAttendanceDtos" :key="index">
          <span class="groupName">{{item.groupName}}</span>
          <span class="attendance">{{item.attendance}}</span>
          <span class="headcount">{{item.headcount}}</span>
          <span class="percents">{{item.percents}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@Width: 192rem;
.entrance {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../assets/entrance/bg.jpg");
  filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;

  .title {
    position: absolute;
    width: 100%;
    height: 60 / @Width;
    top: 60 / @Width;
    background-image: url("../assets/entrance/title.png");
    filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;

    .projectName {
      position: absolute;
      top: -20 / @Width;
      left: 30 / @Width;
      font-size: 32 / @Width;
      font-weight: 700;
      color: #12ecff;
    }
    .dataTimeStr {
      position: absolute;
      top: -15 / @Width;
      right: 30 / @Width;
      font-size: 22 / @Width;
      color: #ffffff;
    }
  }

  .wrapTitle {
    position: absolute;
    width: 100%;
    top: 29 / @Width;
    font-size: 30 / @Width;
    text-align: center;
  }

  .wrapLeft {
    position: absolute;
    top: 141 / @Width;
    left: 10 / @Width;
    width: 639 / @Width;
    height: 929 / @Width;
    background-image: url("../assets/entrance/wrapLeft.png");
    filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;

    .icon {
      width: 238 / @Width;
      height: 298 / @Width;
      border-radius: 20 / @Width;
      position: absolute;
      top: 165 / @Width;
      left: 200 / @Width;
    }
    .personInfo {
      position: absolute;
      top: 530 / @Width;
      width: 100%;
      display: flex;
      justify-content: center;
      span {
        margin: 0 20 / @Width;
      }
      .personName {
        font-size: 36 / @Width;
        color: #12ecff;
        font-weight: 700;
      }

      .personGz {
        margin-top: 6 / @Width;
        font-size: 28 / @Width;
        color: #f9be32;
        font-weight: 700;
      }
    }
    .personNum {
      position: absolute;
      top: 596 / @Width;
      width: 100%;
      text-align: center;
      font-size: 28 / @Width;
      color: #12ecff;
    }
    .personMore {
      position: absolute;
      top: 673 / @Width;
      width: 100%;
      height: 196 / @Width;
      text-align: center;
      font-size: 24 / @Width;
      color: #12ecff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .row {
        display: flex;
        justify-content: center;
        span {
          margin: 0 20 / @Width;
        }
        .personSpan {
          width: 200 / @Width;
        }
        .tal {
          text-align-last: left;
        }
        .tar {
          text-align: right;
        }
      }
    }
  }

  .wrapMidTop {
    position: absolute;
    top: 141 / @Width;
    left: 651 / @Width;
    width: 699 / @Width;
    height: 464 / @Width;
    background-image: url("../assets/entrance/wrapMid.png");
    filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;

    .wrapMidTopContent {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .wrapMidTopContentItem {
        width: 178 / @Width;
        height: 204 / @Width;
        background-image: url("../assets/entrance/num.png");
        filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
        -moz-background-size: 100% 100%;
        background-size: 100% 100%;
        margin: 0 20 / @Width;
        line-height: 204 / @Width;
        text-align: center;
        font-size: 40 / @Width;
        color: #f9be32;
        position: relative;

        .wrapMidTopContentItemName {
          width: 100%;
          position: absolute;
          bottom: -150 / @Width;
          left: 0;
          font-size: 28 / @Width;
          color: #12ecff;
        }
      }
    }
  }

  .wrapMidBottom {
    position: absolute;
    bottom: 10 / @Width;
    left: 651 / @Width;
    width: 699 / @Width;
    height: 464 / @Width;
    background-image: url("../assets/entrance/wrapMid.png");
    filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;

    .entranceWrap {
      position: absolute;
      left: 45 / @Width;
      bottom: 45 / @Width;
      width: 609 / @Width;
      height: 286 / @Width;

      .entranceWrapItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20 / @Width;
        color: #12ECFF;
        margin: 5 / @Width 0;
        span {
          text-align: center;
          margin: 0 7 / @Width;
        }
        .icon {
          width: 45 / @Width;
          height: 45 / @Width;

          img {
            width: 45 / @Width;
            height: 45 / @Width;
            border-radius: 10 / @Width;
          }
        }
        .name {
          width: 90 / @Width;
        }
        .work {
          flex-grow: 1;
        }
        .inout {
          width: 50 / @Width;
        }
        .time {
          width: 90 / @Width;
        }
      }
    }
  }

  .wrapRight {
    position: absolute;
    top: 141 / @Width;
    right: 10 / @Width;
    width: 559 / @Width;
    height: 929 / @Width;
    background-image: url("../assets/entrance/wrapRight.png");
    filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;

    .groupWrap {
      position: absolute;
      left: 45 / @Width;
      bottom: 45 / @Width;
      width: 469 / @Width;
      height: 751 / @Width;
      .groupWrapItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20 / @Width;
        color: #12ECFF;
        margin: 15 / @Width 0;
        span {
          text-align: center;
          margin: 0 7 / @Width;
        }
        .groupName {
          flex-grow: 1;
          text-align-last: left;
        }
        .attendance,
        .headcount {
          width: 80 / @Width;
        }
        .percents {
          width: 60 / @Width;
        }
      }
    }
  }
}
</style>

<script>
export default {
  data() {
    return {
      isFullScreen: false,
      projectName: "航企生活保障基地项目（一期）项目",
      dataTimeStr: "2021.1.20 星期四 14:00:00",
      arenaNumber: 0,
      localeNumber: 0,
      outNumber: 0,
      groupAttendanceDtos: [],
      ws: null,
      projectId: this.$route.params.id,
      message: [],
    };
  },
  computed: {
    currentPerson: function () {
      if (this.message.length) {
        return this.message[0];
      } else {
        return {
          consumerNo: "000000",
          groupName: "----",
          inOut: 0,
          nationality: "----",
          corporationName: "----",
          sex: "--",
          sendface: "----",
          readDate: "00:00:00",
          idPhoto: "https://view.qingxiniot.com/icon.png",
          title: "工种",
          consumerName: "姓名",
          workingState: "----",
        };
      }
    },
  },
  mounted() {
    this.onSetTimer(); // 设置当前时间
    this.getCountData(); // 获取统计数据
    this.onSocketConnect(); // 创建长链接
  },
  methods: {
    // 设置系统时钟
    onSetTimer: function () {
      setInterval(() => {
        var time = new Date();
        time.getYear(); //获取当前年份
        time.getFullYear(); //获取完整的年份(4位,1970-???)
        time.getMonth(); //获取当前月份(0-11,0代表1月)
        time.getDate(); //获取当前日(1-31)
        time.getDay(); //获取当前星期X(0-6,0代表星期天)
        time.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
        time.getHours(); //获取当前小时数(0-23)
        time.getMinutes(); //获取当前分钟数(0-59)
        time.getSeconds(); //获取当前秒数(0-59)
        time.getMilliseconds(); //获取当前毫秒数(0-999)
        time.toLocaleDateString(); //获取当前日期
        function getWeek(num) {
          if (num == 1) return "一";
          if (num == 2) return "二";
          if (num == 3) return "三";
          if (num == 4) return "四";
          if (num == 5) return "五";
          if (num == 6) return "六";
          if (num == 0) return "日";
        }
        var format = function (val) {
          if (val < 10) {
            return "0" + val;
          } else {
            return val;
          }
        };
        this.dataTimeStr = `${time.getFullYear()}.${format(
          time.getMonth() + 1
        )}.${format(time.getDate())} 星期${getWeek(time.getDay())} ${format(
          time.getHours()
        )}:${format(time.getMinutes())}:${format(time.getSeconds())}`;
      }, 1000);
    },
    // 切换全屏状态
    toggleFullscreen() {
      if (this.isFullScreen) {
        this.exitFullScreen();
      } else {
        this.enterFullScreen();
      }
      this.isFullScreen = !this.isFullScreen;
    },
    // 进入全屏
    enterFullScreen: function () {
      let el = document.documentElement;
      let rfs =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;
      if (rfs) {
        // typeof rfs != "undefined" && rfs
        rfs.call(el);
      } else if (typeof window.ActiveXObject !== "undefined") {
        // for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
        let wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    // 退出全屏
    exitFullScreen: function () {
      let el = document;
      let cfs =
        el.cancelFullScreen ||
        el.mozCancelFullScreen ||
        el.msExitFullscreen ||
        el.webkitExitFullscreen ||
        el.exitFullscreen;
      if (cfs) {
        // typeof cfs != "undefined" && cfs
        cfs.call(el);
      } else if (typeof window.ActiveXObject !== "undefined") {
        // for IE，这里和fullScreen相同，模拟按下F11键退出全屏
        let wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    // 获取统计数据
    getCountData: function () {
      var data = {
        projectId: this.projectId,
      };
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/turnstile/groupAttendance",
        data: JSON.stringify(data),
      })
        .then((res) => {
          // console.log('getCountData =>', res)
          this.arenaNumber = res.data.arenaNumber;
          this.localeNumber = res.data.localeNumber;
          this.outNumber = res.data.outNumber;
          this.groupAttendanceDtos = res.data.groupAttendanceDtos;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 创建长链接
    onSocketConnect: function () {
      var that = this;
      this.message.length = 0;
      that.ws = new WebSocket(
        "ws://view.qingxiniot.com:18977/ws?uid=" + that.projectId
      );
      that.ws.onmessage = function (evt) {
        var received_msg = evt.data;
        that.message.unshift(JSON.parse(received_msg)); // 将接收到的消息加入消息队列
        if (that.message.length > 5) {
          that.message.pop()
        }
        that.getCountData(); // 刷新统计数据
      };
    },
    // 获取出入门  0出门  1进门
    onGetInOut: function (data) {
      if (data == 1) {
        return '进门'
      } else {
        return '出门'
      }
    },
    //  人脸机状态  0成功  1失败 2未发送
    onGetSendface: function (data) {
      if (data == '----') {
        return '----'
      }
      if (data == '0') {
        return '成功'
      }
      if(data == '1') {
        return '失败'
      }
      if(data == '2') {
        return '未发送'
      }
    },
    // 性别  0男 1女
    onGetSex: function (data) {
      if (data == '--') {
        return '--'
      }
      if (data == '0') {
        return '男'
      }
      if(data == '1') {
        return '女'
      }
    },
    // 员工状态 1在场 2退场
    onGetWorkingState: function (data) {
      if (data == '----') {
        return '----'
      }
      if (data == '1') {
        return '在场'
      }
      if(data == '2') {
        return '退场'
      }
    }
  },
};
</script>
